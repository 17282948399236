import React, { useRef } from 'react'
import { Link } from 'gatsby'
import styles from './index.module.css'
import Button from '../../Button'
import BusCard from '../../BusCard'
import milkyImg from '../../../assets/milky.jpg'
import mailImg from '../../../assets/mail.png'
import { c, useScrollEv } from '../../../util'

export default function ContactOverview() {
  const rootRef = useRef(null);

  const inView = useScrollEv(250, (currScroll) => currScroll > (rootRef.current.offsetTop - (window.innerHeight * 0.2)));

  return (
    <div ref={rootRef} className={styles.root} style={{ backgroundImage: `url(${milkyImg})` }}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img className={styles.mail} alt="Mail" src={mailImg} />
          
        </div>
        <div className={c([styles.contentText, inView && styles.contentTextInView])}>
          Get in touch with us today so we can start discussing your project requirements. We would be proud to deliver a great solution which produces positive impacts for your business and community. Let us know if you have any questions about services that we offer.
        </div>
        {/* <div className={c([styles.imgBg, inView && styles.imgBgInView])}></div> */}
        {/* <img className={c([styles.img, inView && styles.imgInView])} src={compImg} alt="Comp" /> */}
      </div>
      <div className={styles.content2}>
        <BusCard />
        <Link to='/contact/'>
          <Button>Get in touch!</Button>
        </Link>
      </div>
    </div>
  );
}
