import React from 'react';
import styles from './index.module.css'
import { Link } from 'gatsby';
import arrow from '../../assets/arrow.png'

export default function ArrowLink({ text, to }) {
  return (
    <div className={styles.root}>
      <Link to={to}>
	<div className={styles.inner}>
	  <span>{text}</span>
	  <img className={styles.arrow} alt="arrow" src={arrow} />
	</div>
      </Link>
    </div>
  );
}
