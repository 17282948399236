import React from "react"
import styles from './index.module.css'
import Layout from '../components/Layout'
import Heading from '../components/Landing/Heading'
import ServiceOverview from '../components/Landing/ServiceOverview'
import ProjectsOverview from '../components/Landing/ProjectsOverview'
import ContactOverview from '../components/Landing/ContactOverview'
import Meta from '../components/Meta'

const META_DESCRIPTION = 'Torquedev is a software architecture, full-stack development and DevOps agency/consultancy. With our expertise, we deliver well built cloud-native Node/React applications.'

export default function Home() {
  return (
    <Layout>
      <div className={styles.content}>
        <Meta description={META_DESCRIPTION} />
        <Heading />
        <ServiceOverview />
        <ProjectsOverview />
        <ContactOverview />
      </div>
    </Layout>
  )
}
