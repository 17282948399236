import React, { useRef, useState } from 'react'
import styles from './index.module.css'
import Gears from './Gears'
import ArrowLink from '../../ArrowLink'
import { useScrollEv, c } from '../../../util'

export default function ServiceOverview() {
  const rootRef = useRef(null);

  const inView = useScrollEv(250, (currScroll) => currScroll > (rootRef.current.offsetTop - (window.innerHeight * 0.6)));

  return (
    <div className={styles.root} ref={rootRef}>
      <div className={c([styles.gearsCtr, inView && styles.gearsCtrInView])}>
        <Gears />
      </div>
      <div className={c([styles.text, inView && styles.textInView])}>

        Software architecture, full-stack development and DevOps are the three core abilities of our business. We thrive on delivering great solutions by combining our key strengths.
        <ArrowLink to='/services/' text='Check out our services!' />
      </div>
    </div>
  );

}
