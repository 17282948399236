import React from 'react';
import rubikImg from '../../../assets/rubik.png';
import headImg from '../../../assets/head2.jpg';
import styles from './index.module.css';

export default function Heading() {
  return (
    <div className={styles.heading} style={{ backgroundImage: `url(${headImg})` }}>
      <div className={styles.headingContent}>
        <div className={styles.headingText}>
          We are an application development consultancy. With our expertise, we deliver well-built cloud-native applications.

        </div>
        <img src={rubikImg} className={styles.headingImg} />
      </div>
      <svg className={styles.wave} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffffff" fill-opacity="1" d="M0,160L48,138.7C96,117,192,75,288,96C384,117,480,203,576,202.7C672,203,768,117,864,101.3C960,85,1056,139,1152,160C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      <div style={{ backgroundColor: 'white', width: '100%', marginTop: -5, height: 10 }}></div>
    </div>
  );
}

