import React, { useEffect, useState } from 'react';
import styles from './gears.module.css'
import gear from '../../../assets/gear.png'
import { useScrollEv } from '../../../util.js'

const baseGearStyle = { backgroundImage: `url(${gear})` } 
const gears = [
  {
    className: styles.coolGearMain,
    degOffset: 7,
    content: 'torquedev',
    multi: 1
  },
  {
    className: styles.coolGearSubA,
    degOffset: 0,
    content: 'Arch',
    multi: -1
  },
  {
    className: styles.coolGearSubB,
    degOffset: 1,
    content: 'Full-Stack',
    multi: -1
  },
  {
    className: styles.coolGearSubC,
    degOffset: 2,
    content: 'DevOps',
    multi: -1
  }
];

function createStyle(deg) {
  const style = Object.assign({}, baseGearStyle);
  style.transform = `rotate(${deg}deg)`;
  return style;
}

function Gears() {

  const currScroll = useScrollEv(10, null);

  const deg = (currScroll / 2) % 360;

  return (
    <div className={styles.coolGears}>
      {gears.map((v, i) => (
        <div className={v.className} style={createStyle(v.multi * (deg + v.degOffset))} key={i}>
          <div>{v.content}</div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(Gears);
