import React, { useRef } from 'react'
import ArrowLink from '../../ArrowLink'
import styles from './index.module.css'
import autumnImg from '../../../assets/autumn.jpg'
import compImg from '../../../assets/comp.svg'
import { c, useScrollEv } from '../../../util'

export default function ProjectsOverview() {
  const rootRef = useRef(null);

  const inView = useScrollEv(250, (currScroll) => currScroll > (rootRef.current.offsetTop - (window.innerHeight * 0.2)));

  return (
    <div ref={rootRef} className={styles.root} style={{ backgroundImage: `url(${autumnImg})` }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffffff" fill-opacity="1" d="M0,64L40,64C80,64,160,64,240,90.7C320,117,400,171,480,176C560,181,640,139,720,128C800,117,880,139,960,165.3C1040,192,1120,224,1200,218.7C1280,213,1360,171,1400,149.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
      </svg>
      <div className={styles.content}>
        <div className={c([styles.contentText, inView && styles.contentTextInView])}>
          We are proud to share our projects which showcase our development abilities. After gathering concise project requirements, we work hard to deliver exceptional solutions that perform well for our clients and their customers. Your project could be next to appear on our showcase!
          <ArrowLink to='/projects/' text='See our projects in action' />
        </div>
        <div className={c([styles.imgBg, inView && styles.imgBgInView])}></div>
        <img className={c([styles.img, inView && styles.imgInView])} src={compImg} alt="Comp" />
      </div>
    </div>
  );
}
