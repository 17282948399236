import React from 'react'
import styles from './index.module.css'

export default function Button({ children }) {
  return (
    <div className={styles.root}> 
      {children}
    </div>
  )
}
